import { Routes } from '@angular/router';
import { SidebarLayoutComponent } from './layouts/sidebar-layout/sidebar-layout.component';
import { FocusLayoutComponent } from './layouts/focus-layout/focus-layout.component';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { workspaceGuard } from './guards/workspace.guard';
import { HomeComponent } from './components/general/home/home.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(`/login?redirect=${window.location.pathname}`);

export const routes: Routes = [
  {
    path: '', component: FocusLayoutComponent, children: [
      //these paths don't require authentication
      { path: '', component: HomeComponent, 'data': { 'title': 'Home' } },
      // { path: 'agreement', loadComponent: () => import('./components/general/agreement/agreement.component').then(m => m.AgreementComponent) },
      { path: 'tos', loadComponent: () => import('./components/general/terms-of-service/terms-of-service.component').then(m => m.TermsOfServiceComponent) },
      { path: 'privacy', loadComponent: () => import('./components/general/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent) },
      { path: 'join/:workspaceId/:invitationId', loadComponent: () => import('./components/workspace/accept-invitation/accept-invitation.component').then(m => m.AcceptInvitationComponent) },
    ]
  },

  { path: 'login', loadComponent: () => import('./components/general/login/login.component').then(m => m.LoginComponent) },
  {
    path: '', canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, children: [
      {
        path: '', component: FocusLayoutComponent, children: [
          { path: 'profile', loadComponent: () => import('./components/user/user-profile/user-profile.component').then(m => m.UserProfileComponent) },
          { path: 'select-workspace', loadComponent: () => import('./components/workspace/select-workspace/select-workspace.component').then(m => m.SelectWorkspaceComponent) },
        ]
      },
      {
        path: 'account', title: 'Account Settings', data: { area: 'account' }, children: [
          {
            path: '', component: SidebarLayoutComponent, children: [
              { path: '', redirectTo: 'status', pathMatch: 'full' },
              { path: 'status', title: 'Account Status', loadComponent: () => import('./components/account/account-status/account-status.component').then(m => m.AccountStatusComponent) },
              { path: 'phone', title: 'Account Phone Settings', loadComponent: () => import('./components/account/account-phone-settings/account-phone-settings.component').then(m => m.AccountPhoneSettingsComponent) },
              { path: 'workspaces', title: 'Workspaces', loadComponent: () => import('./components/account/account-workspaces/account-workspaces.component').then(m => m.AccountWorkspacesComponent) },
            ]
          },
          {
            path: '', component: FocusLayoutComponent, children: [
              { path: 'onboarding', title: 'Account Onboarding', loadComponent: () => import('./components/account/account-onboarding/account-onboarding.component').then(m => m.AccountOnboardingComponent) },
            ]
          },
        ]
      },
      {
        path: 'reseller', title: 'Reseller Settings', data: { area: 'reseller' }, children: [
          {
            path: '', component: SidebarLayoutComponent, children: [
              { path: '', redirectTo: 'status', pathMatch: 'full' },
              { path: 'onboarding', loadComponent: () => import('./components/reseller/reseller-onboarding/reseller-onboarding.component').then(m => m.ResellerOnboardingComponent) },
              { path: 'status', title: 'Reseller Status', loadComponent: () => import('./components/reseller/reseller-status/reseller-status.component').then(m => m.ResellerStatusComponent) },
              { path: 'whitelabel', title: 'White-Label Settings', loadComponent: () => import('./components/reseller/reseller-whitelabel/reseller-whitelabel.component').then(m => m.ResellerWhitelabelComponent) },
              // { path: 'plans', title: 'Manage Reseller Plans', loadComponent: () => import('./components/reseller/reseller-manage-plans/reseller-manage-plans.component').then(m => m.ResellerManagePlansComponent) },
              { path: 'plans', title: 'Manage Plans', loadComponent: () => import('./components/admin/manage-plans/manage-plans.component').then(m => m.ManagePlansComponent) },
              { path: 'users', title: 'Manage Users', loadComponent: () => import('./components/admin/manage-users/manage-users.component').then(m => m.ManageUsersComponent) },
              { path: 'blueprints', title: 'Default Blueprints', loadComponent: () => import('./components/admin/default-blueprints/default-blueprints.component').then(m => m.DefaultBlueprintsComponent) },
              { path: 'campaign-templates', title: 'Campaign Templates', loadComponent: () => import('./components/admin/campaign-templates/campaign-templates.component').then(m => m.CampaignTemplatesComponent) },
            ]
          },
          {
            path: '', component: FocusLayoutComponent, children: [
              { path: 'onboarding', title: 'Reseller Onboarding', loadComponent: () => import('./components/reseller/reseller-onboarding/reseller-onboarding.component').then(m => m.ResellerOnboardingComponent) },
            ]
          },

        ]
      },
      {
        path: 'admin', title: 'Admin Area', component: SidebarLayoutComponent, data: { area: 'admin' }, children: [
          { path: '', redirectTo: 'status', pathMatch: 'full' },
          { path: 'status', title: 'VOXR Status', loadComponent: () => import('./components/admin/system-status/system-status.component').then(m => m.SystemStatusComponent) },
          { path: 'users', title: 'Manage Users', loadComponent: () => import('./components/admin/manage-users/manage-users.component').then(m => m.ManageUsersComponent) },
          { path: 'workspaces', title: 'Manage Workspaces', loadComponent: () => import('./components/admin/manage-workspaces/manage-workspaces.component').then(m => m.ManageWorkspacesComponent) },
          { path: 'campaigns', title: 'Manage Campaigns', loadComponent: () => import('./components/admin/admin-manage-campaigns/admin-manage-campaigns.component').then(m => m.AdminManageCampaignsComponent) },
          { path: 'calls', title: 'Call Logs', loadComponent: () => import('./components/admin/admin-manage-calls/admin-manage-calls.component').then(m => m.AdminManageCallsComponent) },
          { path: 'integrations', title: 'Default Integrations', loadComponent: () => import('./components/admin/default-integrations/default-integrations.component').then(m => m.DefaultIntegrationsComponent) },
          { path: 'plans', title: 'Manage Plans', loadComponent: () => import('./components/admin/manage-plans/manage-plans.component').then(m => m.ManagePlansComponent) },
          { path: 'blueprints', title: 'Default Blueprints', loadComponent: () => import('./components/admin/default-blueprints/default-blueprints.component').then(m => m.DefaultBlueprintsComponent) },
          { path: 'campaign-templates', title: 'Campaign Templates', loadComponent: () => import('./components/admin/campaign-templates/campaign-templates.component').then(m => m.CampaignTemplatesComponent) },
          { path: 'vapi', title: 'Manage Vapi', loadComponent: () => import('./components/admin/manage-vapi/manage-vapi.component').then(m => m.ManageVapiComponent) },
          { path: 'logs', title: 'System Logs', loadComponent: () => import('./components/admin/system-logs/system-logs.component').then(m => m.SystemLogsComponent) },
        ]
      },
      {
        path: 'w/:workspace', canActivate: [workspaceGuard], data: { area: 'workspace' }, children: [
          {
            path: '', component: SidebarLayoutComponent, children: [
              // { path: '',  redirectTo: 'assistants', pathMatch: 'full' },
              { path: 'dashboard', loadComponent: () => import('./components/general/dashboard/dashboard.component').then(m => m.DashboardComponent), 'data': { 'title': 'Dashboard' } },
              {
                path: 'assistants', children: [
                  { path: '', 'loadComponent': () => import('./components/assistant/manage-assistants/manage-assistants.component').then(m => m.ManageAssistantsComponent), 'data': { 'title': 'Assistants' } },
                  { path: ':assistantId', 'loadComponent': () => import('./components/assistant/assistant-detail/assistant-detail.component').then(m => m.AssistantDetailComponent), 'data': { 'title': 'Assistants' } },

                ]
              },
              { path: 'contacts', 'loadComponent': () => import('./components/contact/manage-contacts/manage-contacts.component').then(m => m.ManageContactsComponent), 'data': { 'title': 'Assistants' } },
              { path: 'calls', 'loadComponent': () => import('./components/call/calls-list/calls-list.component').then(m => m.CallsListComponent), 'data': { 'title': 'Assistants' } },
              { path: 'blueprints', 'loadComponent': () => import('./components/blueprint/manage-blueprints/manage-blueprints.component').then(m => m.ManageBlueprintsComponent), 'data': { 'title': 'Blueprints' } },
              { path: 'campaigns', 'loadComponent': () => import('./components/campaign/manage-campaigns/manage-campaigns.component').then(m => m.ManageCampaignsComponent), 'data': { 'title': 'Campaigns' } },
              {
                path: 'workspace', children: [
                  { path: 'members', 'loadComponent': () => import('./components/workspace/members/members.component').then(m => m.MembersComponent), 'data': { 'title': 'Settings' } },
                  { path: 'blueprints', 'loadComponent': () => import('./components/workspace/members/members.component').then(m => m.MembersComponent), 'data': { 'title': 'Settings' } },
                  { path: 'settings', 'loadComponent': () => import('./components/workspace/settings/settings.component').then(m => m.SettingsComponent), 'data': { 'title': 'Settings' } },
                ]
              },
              {
                path: 'integration', children: [
                  { path: '', 'loadComponent': () => import('./components/integration/integrations/integrations.component').then(m => m.IntegrationsComponent), 'data': { 'title': 'Integrations' } },
                ]
              }
            ]
          },
          {
            path: '', component: FocusLayoutComponent, children: [
              { path: 'onboarding', loadComponent: () => import('./components/general/onboarding/onboarding.component').then(m => m.OnboardingComponent), 'data': { 'title': 'Onboarding' } },
            ]
          },
        ]
      },
    ]
  },
];
