<section class="uw-page-container">
  <div class="uw-page-fold tw-flex tw-flex-col tw-bg-no-repeat tw-bg-top">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-m-auto ">
      <div class="tw-text-5xl tw-font-semibold">
        Transform Your
      </div>
      <div class="tw-text-5xl tw-font-semibold">
        Business With Voice
      </div>
      <div class="tw-text-7xl tw-font-extrabold text-brand">
        AI Assistants
      </div>
      <div>
        Streamline Operations, Boost Revenue, Reduce Staffing & Training Costs!
      </div>
      <div>
        <a pButton routerLink="/login" severity="warning" class="tw-mt-4">
          <div class="tw-px-7 tw-font-bold tw-py-3">Get Started Now</div>
        </a>
      </div>
    </div>
    <div class="tw-mt-auto text-muted tw-text-center tw-p-3">
      By using our services, you agree to our <a href="/tos" target="_blank"
        class="tw-whitespace-nowrap !text-info">Terms of Service</a>
      <!-- and <a href="/privacy" target="_blank" class="tw-whitespace-nowrap text-danger">[[Privacy
        Policy]]</a> -->
      .
    </div>

  </div>
</section>