import { Component, inject } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { WorkspaceService } from '../../../services/workspace.service';
import { firstValueFrom } from 'rxjs';
import { UserInterface, WhiteLabelConfig, WorkspaceInterface } from 'voxr-shared';
import { UserProfileComponent } from '../../user/user-profile/user-profile.component';
import { SelectWorkspaceComponent } from '../../workspace/select-workspace/select-workspace.component';
import { ButtonModule } from 'primeng/button';
import { SetupBillingComponent } from '../../account/setup-billing/setup-billing.component';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { HomeLandingComponent } from "../home-landing/home-landing.component";
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule, RouterLink,
    UserProfileComponent, SelectWorkspaceComponent, SetupBillingComponent,
    ButtonModule, MatCardModule,
    HomeLandingComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  private userService = inject(UserService);
  private app = inject(AppService);
  private workspaceService = inject(WorkspaceService);
  user!: UserInterface;
  workspaces?: WorkspaceInterface[];
  incompleteProfile = false;
  resellerMismatch = false;
  reseller?: WhiteLabelConfig & { resellerId: string };
  originalReseller?: WhiteLabelConfig & { resellerId: string };

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(async user => await this.load(user));
  }

  loading = true;
  private async load(user?: UserInterface) {
    try {
      this.loading = true;
      if (!user) return;
      this.user = user;
      const profile = this.user?.profile;
      this.incompleteProfile = !this.user.phone || !profile || !profile['firstName'] || !profile['lastName'] || !profile['company'];
      this.workspaces = await firstValueFrom(this.workspaceService.workspaces$);
      this.reseller = await this.app.reseller();
      this.resellerMismatch = user.resellerId != this.reseller?.resellerId;
      if (this.resellerMismatch && user.resellerId) {
        this.originalReseller = await this.app.resellerById(user.resellerId);
      }
    }
    finally {
      this.loading = false;
    }
  }


}
