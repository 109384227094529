import { EventEmitter, inject, Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { AppService } from './app.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private appService = inject(AppService);
  private socket?: Socket;
  connected$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.appService.idToken$.subscribe(async (idToken: string) => {
      this.disconnect();
      if (idToken) this.connect(idToken);
    });
  }

  connect(idToken: string) {
    this.disconnect();

    this.socket = new Socket({
      url: environment.app.serviceUrl,
      options: { auth: { token: idToken } }
    });

    this.socket.on('connect', () => { console.log('Connected to Socket.IO server'); this.connected$.next(true); });
    this.socket.on('disconnect', () => { console.log('Disconnected from Socket.IO server'); this.connected$.next(false); });
    this.socket.on('reconnect', () => { console.log('Reconnected to Socket.IO server'); this.connected$.next(true); });
    this.socket.on('message', (msg: string) => { console.log('Message from server:', msg); });
  }

  disconnect() {
    this.socket?.disconnect();
    delete this.socket;
  }

  onEvent$<T>(event: string) {
    return this.socket!.fromEvent<T>(event);
  }

  emit(event: string, data: any) {
    this.socket?.emit(event, data);
  }
}

