import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { PlanInterface } from 'voxr-shared';
import { BillingService } from '../../../services/billing.service';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { PriceComponent } from '../price/price.component';

@Component({
  selector: 'app-plan-card',
  standalone: true,
  imports: [CommonModule, ButtonModule, CardModule, DividerModule, TagModule, TooltipModule, PriceComponent],
  templateUrl: './plan-card.component.html',
  styleUrl: './plan-card.component.scss'
})
export class PlanCardComponent {
  private billingService = inject(BillingService);
  @Input() mode: 'tag' | 'card' = 'card';
  @Input({ alias: 'plan', required: true }) set planOrId(value: string | PlanInterface | undefined) {
    if (typeof value === 'string') setTimeout(async () => {
      const plans = await this.billingService.plans();
      this.plan = plans?.find(_ => _._id!.toString() === value);
      if(value && !this.plan) this.plan = await this.billingService.getPlan(value);
    });
    else this.plan = value;
  }
  plan?: PlanInterface;
}
