@if(price){
<div class="tw-flex tw-gap-2">
  @if(icon){<i [class]="icon"></i>}
  <div class="tw-flex tw-flex-col tw-gap-1">
    @let hasTiers = price.tiers?.length! > 1 && (!max || max > price.tiers?.[0]?.up_to!);
    @switch (type || price.metadata['type']) {

    @case('plan'){
    <div>
      @if(price.unit_amount){ {{price.unit_amount/100|currency}} per month}
      @else{ Free Plan }
    </div>
    }

    @case ('minutes'){
    <div> {{price.tiers?.[0]?.up_to || 'Unlimited'}} Call Minute(s) @if(hasTiers){included}</div>
    <div class="text-secondary tw-text-sm">
      @if(hasTiers) {
      Additional minutes at {{price.tiers?.[1]?.unit_amount!/100|currency}} per minute
      }@else if(price.tiers?.[0]?.unit_amount){
      {{price.tiers?.[0]?.unit_amount!/100|currency}} per minute
      }
    </div>
    }

    @case ('number') {
    <div> {{price.tiers?.[0]?.up_to || 'Unlimited'}} Phone Number(s) @if(hasTiers){included}</div>
    <div class="text-secondary tw-text-sm">
      @if(hasTiers) {
      Additional phone numbers at {{price.tiers?.[1]?.unit_amount!/100|currency}} per phone number, per month
      }@else if(price.tiers?.[0]?.unit_amount){
      {{price.tiers?.[0]?.unit_amount!/100|currency}} per phone number, per month
      }
    </div>
    }

    @case ('workspace') {
    <div> {{price.tiers?.[0]?.up_to || 'Unlimited'}} Workspace(s) @if(hasTiers){included}</div>
    <div class="text-secondary tw-text-sm">
      @if(hasTiers) {
      Additional workspaces at {{price.tiers?.[1]?.unit_amount!/100|currency}} per phone number, per month
      }@else if(price.tiers?.[0]?.unit_amount){
      {{price.tiers?.[0]?.unit_amount!/100|currency}} per phone number, per month
      }
    </div>
    }

    @case ('member') {
    <div> {{price.tiers?.[0]?.up_to || 'Unlimited'}} Member(s) @if(hasTiers){included}</div>
    <div class="text-secondary tw-text-sm">
      @if(hasTiers) {
      Additional members at {{price.tiers?.[1]?.unit_amount!/100|currency}} per member, per month
      }@else if(price.tiers?.[0]?.unit_amount){
      {{price.tiers?.[0]?.unit_amount!/100|currency}} per member, per month
      }
    </div>
    }

    @case ('campaign') {
    <div> {{price.tiers?.[0]?.up_to || 'Unlimited'}} Active Campaign(s) @if(hasTiers){included}</div>
    <div class="text-secondary tw-text-sm">
      @if(hasTiers) {
      Additional active campaign at {{price.tiers?.[1]?.unit_amount!/100|currency}} per active campaign, per month
      }@else if(price.tiers?.[0]?.unit_amount){
      {{price.tiers?.[0]?.unit_amount!/100|currency}} per active campaign, per month
      }
    </div>
    }

    }
  </div>
</div>
}