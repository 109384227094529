import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { UserService } from '../../services/user.service';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { WorkspaceService } from '../../services/workspace.service';
import { FormsModule } from '@angular/forms';
import { UserStatusComponent } from '../../components/user/user-status/user-status.component';
import { UserInterface, WorkspaceInterface } from 'voxr-shared';
import { TransferAvailabilityComponent } from '../../components/general/transfer-availability/transfer-availability.component';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { WorkspaceRouterLinkDirective } from '../../directives/wRouterLink.directive';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-sidebar-layout',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, RouterLinkActive, TagModule, TooltipModule, ButtonModule,
    DropdownModule, TransferAvailabilityComponent, UserStatusComponent, WorkspaceRouterLinkDirective],
  templateUrl: './sidebar-layout.component.html',
  styleUrl: './sidebar-layout.component.scss'
})
export class SidebarLayoutComponent {
  private app = inject(AppService);
  private userService = inject(UserService);
  private workspaceService = inject(WorkspaceService);
  private route = inject(ActivatedRoute);

  showSidebar = true;
  user?: UserInterface | null;
  workspaces?: WorkspaceInterface[];
  workspace?: WorkspaceInterface | null;
  workspaceRole?: 'owner' | 'admin' | 'agent';
  area?: string;
  needOnboarding?: boolean;
  brand: any;
  logoUrl?: string;

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(user => this.loadUser(user));
    this.workspaceService.workspaces$.pipe(takeUntilDestroyed()).subscribe(workspaces => {
      this.workspaces = workspaces?.filter(_ => _.ownerId == this.user?._id || _.members?.find(m => m.userId == this.user?._id));
    });
    this.workspaceService.workspace$.pipe(takeUntilDestroyed()).subscribe(workspace => {
      this.workspace = workspace;
      if (workspace?.ownerId == this.user?._id)
        this.workspaceRole = 'owner';
      else {
        const roles = workspace?.members?.find(m => m.userId == this.user?._id)?.roles;
        if (roles?.includes('admin')) this.workspaceRole = 'admin';
        else this.workspaceRole = 'agent';
      }
    });
  }

  async ngOnInit() {
    this.area = this.route.snapshot.data['area'];
    this.brand = await this.app.reseller();
    this.logoUrl = this.brand?.ui?.['logoUrl'] || 'assets/images/logo/voxr-logotype.png';
  }

  private loadUser(user?: UserInterface | null): void {
    // if(user &&  !user.isApproved)
    //   this.router.navigate(['/register']);
    this.user = user;

    if (user?.resellerConfig?.whiteLabel?.domains?.length) {
      this.externalResellerDomain = user.resellerConfig.whiteLabel.domains.includes(location.origin) ? undefined : user.resellerConfig.whiteLabel.domains[0];
    }
  }
  externalResellerDomain?: string;

  async setWorkspace(workspaceId: string) {
    this.workspaceService.setWorkspace(workspaceId);
    // this.workspaceService.workspace$
    //   .pipe(filter(w => w?._id?.toString() == workspaceId)).subscribe(() => window.location.href = '/dashboard');
  }


}

