@if(plan){
@switch (mode) {
@case ('tag') {<p-tag severity="info" [pTooltip]="plan.description">{{plan.name}}</p-tag>}
@default{
<p-card [header]="plan.name">
  <div class="tw-flex tw-flex-col tw-gap-2">
    <div class="text-secondary">
      {{plan.description}}
    </div>
    <div>
      <p-divider />
      <div class="tw-flex tw-w-full tw-justify-between">
        <strong><app-price [price]="plan.planPrice" icon="pi pi-check text-success" type="plan" /></strong>
        @if(!plan.isPublic) {<p-tag severity="warning">Exclusive Plan</p-tag>}
        @if(plan.isMaster) {<p-tag severity="secondary">Master Plan</p-tag>}
      </div>
      <p-divider />
    </div>
    <app-price [price]="plan.workspacePrice" [max]="plan.maxWorkspace" icon="pi pi-check text-success"
      type="workspace" />
    <app-price [price]="plan.numberPrice" [max]="plan.maxNumber" icon="pi pi-check text-success" type="number" />
    <app-price [price]="plan.minutesPrice" [max]="plan.maxMinutes" icon="pi pi-check text-success" type="minutes" />
    <app-price [price]="plan.campaignPrice" [max]="plan.maxCampaign" icon="pi pi-check text-success" type="campaign" />
    <app-price [price]="plan.memberPrice" [max]="plan.maxMember" icon="pi pi-check text-success" type="member" />
  </div>
  <ng-template pTemplate="footer">
    <ng-content select="[footer]"></ng-content>
  </ng-template>
</p-card>
}
}
}