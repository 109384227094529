<div class="uw-page-container " [ngClass]="{'tw-animate-pulse': loading}">
  @if(user) {
  <div class="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-py-8">
    @if(incompleteProfile) { <app-user-profile /> }
    @else {
    @if(user.resellerConfig?.isApproved) {
    <mat-card>
      <mat-card-content>
        To access your reseller panel, click the button below.
        <div class="tw-p-2 tw-text-center">
          <a pButton [routerLink]="['/reseller']" label="Go to reseller panel" class="tw-mr-2"></a>
        </div>
      </mat-card-content>
    </mat-card>
    }
  
  
    @if(resellerMismatch) {
    @if(!workspaces?.length) {
    <mat-card>
      <mat-card-content>
        You have been signed up with a different reseller. Go to
        @if(originalReseller?.domains?.length) {
        <a class="tw-font-bold tw-text-blue-500" [href]="originalReseller!.domains![0]">{{originalReseller!.name}}</a>
        } @else {
        <a class="tw-font-bold tw-text-blue-500" href="https://www.voxrapp.com">voxrapp.com</a>
        } website to access your customer panel.
      </mat-card-content>
    </mat-card>
    }
    }
    @else {
    @if((user.isApproved || !workspaces?.length) && user._metadata?.billing?.subscription?.status != 'active') {
    <app-setup-billing />
    } @else {
    @if(!user.isApproved && !workspaces?.length){
    <mat-card>
      <mat-card-content>
        Thank you for registering with us. You will be contacted soon by our team to get onboarded.
      </mat-card-content>
    </mat-card>
    }
    }
    }
  
    @if(workspaces?.length || user._metadata?.billing?.status == 'active'){ <app-select-workspace /> }
    }
  </div>
  } @else {
  <app-home-landing />
  }
</div>